<template>
    <v-row>
      <v-col cols="12">
        <v-text-field
        outlined
        v-model="cardHolder"
        :rules="[v => (!!v && v.length > 2) || 'Item is required at least 3 characters', v => /^[a-zA-Z\s]*$/.test(v) || 'Name Must be letters only with no spaces']"
        label="Card Holder Name"
        required
        color="blue"
        prepend-inner-icon="mdi-account"
        @input="setCardHolder"
        id="credit-card-holder"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
          <v-text-field
          id="credit-cvv"
          v-model="cvv"
          :rules="cvvRules"
          label="CVV"
          required
          outlined
          class="remove-controls"
          type="number"
          step="1"
          color="blue"
          @input="setCVV"
          ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
        id="card-number"
        prepend-inner-icon="mdi-credit-card-outline"
        v-model="cardNum"
        :rules="cardRules"
        label="Card number"
        required
        outlined
        class="remove-controls"
        type="number"
        @input="changeCardNum"
        color="blue"
        :suffix="cardCode"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <date-picker
        v-model.lazy="cardExpireDate"
        clearable
        editable
        type="month"
        valueType="YYYY-MM"
        placeholder="Card Expire Date"
        :default-value="new Date()"
        style="width: 100%; height: 56px;"
        :disabled-date="cardExpiryDateRange"
        required
        format="M/YYYY"
        append-to-body
        :input-attr="{id: 'cardExpiry'}"
        @focus="changeInputColor('passenger-cardExpiry', '#2196F3')"
        @blur="changeInputColor('passenger-cardExpiry', '#9e9e9e')"
        @input-error="changeInputColor('passenger-cardExpiry', 'red')"
        @input="changeExpiry()"
        :id="`passenger-cardExpiry`"
        >
        </date-picker>
        <span class="caption grey--text">Hint: MM/YYYY</span>
      </v-col>
    </v-row>
</template>

<script>
import cardType from 'credit-card-type'

export default {
  data () {
    return {
      cvv: '',
      cardNum: '',
      cardExpireDate: '',
      cardCode: '',
      cardHolder: '',
      menu: false,
      validCard: true,
      cardRules: [
        v => !!v || 'Card number is required',
        v => (!isNaN(v)) || 'Card number must be only numbers',
        v => v.length > 12 || 'Card number must be at least 13 characters',
        v => v.length < 20 || 'Card number must be at most 19 characters',
        v => this.vllidateCard(v) || 'Please enter a valid card number'
      ],
      cvvRules: [
        v => !!v || 'CVV is required',
        v => (!isNaN(v)) || 'CVV must be only numbers',
        v => Number.isInteger(Number(v)) || 'CVV must be integers',
        v => v.length > 2 || 'CVV must be at least 3 characters',
        v => v.length < 5 || 'CVV must be at most 4 characters'
      ],
      requiredRule: [
        v => !!v || 'Field is required'
      ]
    }
  },
  watch: {
    cardNum (val) {
    }
  },
  methods: {
    changeInputColor (id, color) {
      document.getElementById(id).style.borderColor = color
    },
    cardExpiryDateRange (date) {
      return date < new Date()
    },
    vllidateCard (value) {
      const cards = cardType(value)
      if (cards.length > 0) return true
      else return false
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${months[month - 1]} ${year}`
    },
    changeCardNum () {
      if (this.cardNum) {
        const cards = cardType(this.cardNum)
        if (cards.length > 0) {
          this.cardCode = cards[0].type
        }
      } else this.cardCode = ''
      this.$emit('card', this.cardNum, this.cardCode)
    },
    changeExpiry () {
      if (this.cardExpireDate) {
        this.$emit('expire', this.cardExpireDate)
      }
    },
    setCVV () {
      this.$emit('cvv', this.cvv)
    },
    setCardHolder () {
      this.$emit('holder', this.cardHolder)
    }
  }
}
</script>

<style>
  .remove-controls input[type='number'] {
    -moz-appearance:textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  .mx-input {
    height: 56px;
  }
  .address-autocomplete {
    width: 100%;
    height: 56px;
    outline: none;
    border: 0.5px solid #9e9e9e;
    border-radius: 5px;
    padding: 0 10px;
  }
</style>
